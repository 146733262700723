const Data = {
  checks: [
    {
      link: "/screenings/identity-verification/",
      path: "id",
      title: "ID Verification",
    },
    {
      link: "/screenings/address-verification/",
      path: "address",
      title: "Address Verification",
    },
    {
      link: "/screenings/employment-verification/",
      path: "employment",
      title: "Employment \nVerification",
    },
    {
      link: "/screenings/education-verification/",
      path: "education",
      title: "Education Verification",
    },
    {
      link: "/screenings/criminal-record-check/",
      path: "criminal",
      title: "Criminal Records \nCheck",
    },
    {
      link: "/screenings/reference-verification/",
      path: "reference",
      title: "Reference Check",
    },
    {
      link: "/screenings/court-record-check/",
      path: "court",
      title: "Court Record \nVerification",
    },
    {
      link: "/screenings/global-database-verification/",
      path: "global",
      title: "Global database \nVerification",
    },
    {
      link: "/screenings/credit-check/",
      path: "credit",
      title: "Credit Check",
    },
    {
      link: "/screenings/social-media-verification/",
      path: "social",
      title: "Social Media \nVerification",
    },
    {
      link: "/screenings/drug-test-verification/",
      path: "drug",
      title: "Drug Test Screening",
    },
    {
      link: "/screenings/passport-verification/",
      path: "passport",
      title: "Passport Verification",
    },
    {
      link: "/screenings/din-verification/",
      path: "din",
      title: "DIN Verification",
    },
  ],

  Explore: [
    {
      link: "/industry/fintech/",
      path: "fintech",
      title: "Fintech",
    },
    {
      link: "/industry/e-commerce/",
      path: "e-commerce",
      title: "E-commerce",
    },
    {
      link: "/industry/staffing-agencies/",
      path: "staffing",
      title: "Staffing",
    },
    {
      link: "/industry/technology/",
      path: "it-sass",
      title: "IT and SaaS",
    },
    {
      link: "/industry/healthcare/",
      path: "healthcare",
      title: "Healthcare",
    },
    {
      link: "/human-resources/",
      path: "hr",
      title: "HR",
    },
    {
      link: "/operations/",
      path: "operations",
      title: "Operations",
    },
    {
      link: "/compliance/",
      path: "compliance",
      title: "Compliance",
    },
  ],

  AllCheck: [
    {
      header: "SpringVerify’s suite of checks \nfor the Fintech Industry",
      para: `SpringVerify’s digital solutions make it easy for employers to screen candidates \nwithout hassle.
      Here are the recommended suite of checks for you as per the \nindustry standards`,
      IconLink: [
        {
          path: "id",
          link: "https://in.springverify.com/screenings/identity-verification/",
          title: "ID Checks",
        },
        {
          path: "address",
          link: "https://in.springverify.com/screenings/address-verification/",
          title: "Address Verification",
        },
        {
          path: "employment",
          link:
            "https://in.springverify.com/screenings/employment-verification/",
          title: "Employment Verifications",
        },
        {
          path: "education",
          link:
            "https://in.springverify.com/screenings/education-verification/",
          title: "Education Verification",
        },
        {
          path: "criminal",
          link: "https://in.springverify.com/screenings/criminal-record-check/",
          title: "Criminal Record Check",
        },
        {
          path: "reference",
          link:
            "https://in.springverify.com/screenings/reference-verification/",
          title: "Reference Check",
        },
        {
          path: "court",
          link: "https://in.springverify.com/screenings/court-record-check/",
          title: "Court Record Verification",
        },
        {
          path: "global",
          link:
            "https://in.springverify.com/screenings/global-database-verification/",
          title: "Global Database Verification",
        },
        {
          path: "credit",
          link: "https://in.springverify.com/screenings/credit-check/",
          title: "Credit Check",
        },
        {
          path: "social",
          link:
            "https://in.springverify.com/screenings/social-media-verification/",
          title: "Social Media Verification",
        },
        {
          path: "drug",
          link:
            "https://in.springverify.com/screenings/drug-test-verification/",
          title: "Drug Test Screening",
        },
        {
          path: "passport",
          link: "https://in.springverify.com/screenings/passport-verification/",
          title: "Passport Verification",
        },
        {
          path: "din",
          link: "https://in.springverify.com/screenings/din-verification/",
          title: "DIN Verification",
        },
      ],
      Illustration: [
        {
          path: "AllCheck",
        },
      ],
    },
  ],

  TAAllCheck: [
    {
      header: "All the checks you \nneed in one place",
      IconLink: [
        {
          path: "id",
          link: "https://in.springverify.com/screenings/identity-verification/",
          title: "ID Checks",
        },
        {
          path: "address",
          link: "https://in.springverify.com/screenings/address-verification/",
          title: "Address Verification",
        },
        {
          path: "employment",
          link:
            "https://in.springverify.com/screenings/employment-verification/",
          title: "Employment Verifications",
        },
        {
          path: "education",
          link:
            "https://in.springverify.com/screenings/education-verification/",
          title: "Education Verification",
        },
        {
          path: "criminal",
          link: "https://in.springverify.com/screenings/criminal-record-check/",
          title: "Criminal Record Check",
        },
        {
          path: "reference",
          link:
            "https://in.springverify.com/screenings/reference-verification/",
          title: "Reference Check",
        },
        {
          path: "court",
          link: "https://in.springverify.com/screenings/court-record-check/",
          title: "Court Record Verification",
        },
        {
          path: "global",
          link:
            "https://in.springverify.com/screenings/global-database-verification/",
          title: "Global Database Verification",
        },
        {
          path: "credit",
          link: "https://in.springverify.com/screenings/credit-check/",
          title: "Credit Check",
        },
        {
          path: "social",
          link:
            "https://in.springverify.com/screenings/social-media-verification/",
          title: "Social Media Verification",
        },
        {
          path: "drug",
          link:
            "https://in.springverify.com/screenings/drug-test-verification/",
          title: "Drug Test Screening",
        },
        {
          path: "passport",
          link: "https://in.springverify.com/screenings/passport-verification/",
          title: "Passport Verification",
        },
        {
          path: "din",
          link: "https://in.springverify.com/screenings/din-verification/",
          title: "DIN Verification",
        },
      ],
      Illustration: [
        {
          path: "AllCheck",
        },
      ],
    },
  ],

  HRAllCheck: [
    {
      header: "All the checks you \nneed in one place",
      IconLink: [
        {
          path: "id",
          link: "https://in.springverify.com/screenings/identity-verification/",
          title: "ID Checks",
        },
        {
          path: "address",
          link: "https://in.springverify.com/screenings/address-verification/",
          title: "Address Verification",
        },
        {
          path: "employment",
          link:
            "https://in.springverify.com/screenings/employment-verification/",
          title: "Employment Verifications",
        },
        {
          path: "reference",
          link:
            "https://in.springverify.com/screenings/reference-verification/",
          title: "Reference Check",
        },
        {
          path: "criminal",
          link: "https://in.springverify.com/screenings/criminal-record-check/",
          title: "Criminal Record Check",
        },
        {
          path: "education",
          link:
            "https://in.springverify.com/screenings/education-verification/",
          title: "Education Verification",
        },
        {
          path: "court",
          link: "https://in.springverify.com/screenings/court-record-check/",
          title: "Court Record Verification",
        },
        {
          path: "global",
          link:
            "https://in.springverify.com/screenings/global-database-verification/",
          title: "Global Database Verification",
        },
        {
          path: "credit",
          link: "https://in.springverify.com/screenings/credit-check/",
          title: "Credit Check",
        },
        {
          path: "social",
          link:
            "https://in.springverify.com/screenings/social-media-verification/",
          title: "Social Media Verification",
        },
        {
          path: "drug",
          link:
            "https://in.springverify.com/screenings/drug-test-verification/",
          title: "Drug Test Screening",
        },
        {
          path: "passport",
          link: "https://in.springverify.com/screenings/passport-verification/",
          title: "Passport Verification",
        },
        {
          path: "din",
          link: "https://in.springverify.com/screenings/din-verification/",
          title: "DIN Verification",
        },
      ],
      Illustration: [
        {
          path: "HRAllCheck",
        },
      ],
    },
  ],

  BrandsTemp: [
    {
      header: "Brands you trust, trust us",
      Logos: [
        {
          path: "https://assets-sv-in.gumlet.io/LP/Brands/flipkart.svg",
        },
        {
          path: "https://assets-sv-in.gumlet.io/LP/Brands/inshorts.svg",
        },
        {
          path: "https://assets-sv-in.gumlet.io/LP/Brands/rently.svg",
        },
        {
          path: "https://assets-sv-in.gumlet.io/LP/Brands/airmeet.svg",
        },
        {
          path: "https://assets-sv-in.gumlet.io/LP/Brands/simpl.svg",
        },
        {
          path: "https://assets-sv-in.gumlet.io/LP/Brands/meesho.svg",
        },
      ],
    },
  ],
  HRBrandsTemp: [
    {
      header: "Top brands that trust SpringVerify",
      Logos: [
        {
          path: "https://assets-sv-in.gumlet.io/LP/Brands/flipkart.svg",
        },
        {
          path: "https://assets-sv-in.gumlet.io/LP/Brands/inshorts.svg",
        },
        {
          path: "https://assets-sv-in.gumlet.io/LP/Brands/rently.svg",
        },
        {
          path: "https://assets-sv-in.gumlet.io/LP/Brands/airmeet.svg",
        },
        {
          path: "https://assets-sv-in.gumlet.io/LP/Brands/simpl.svg",
        },
        {
          path: "https://assets-sv-in.gumlet.io/LP/Brands/meesho.svg",
        },
      ],
    },
  ],
  CRBrandsTemp: [
    {
      header: "Top brands that trust SpringVerify",
      Logos: [
        {
          path: "https://assets-sv-in.gumlet.io/LP/Brands/indorama.svg",
        },
        {
          path: "https://assets-sv-in.gumlet.io/LP/Brands/inshorts.svg",
        },
        {
          path: "https://assets-sv-in.gumlet.io/LP/Brands/rently.svg",
        },
        {
          path: "https://assets-sv-in.gumlet.io/LP/Brands/airmeet.svg",
        },
        {
          path: "https://assets-sv-in.gumlet.io/LP/Brands/simpl.svg",
        },
        {
          path: "https://assets-sv-in.gumlet.io/LP/Brands/civica.svg",
        },
      ],
    },
  ],

  ECommerceAllCheck: [
    {
      header: "Recommended suite of checks \nbuilt for you",
      IconLink: [
        {
          path: "id",
          link: "https://in.springverify.com/screenings/identity-verification/",
          title: "ID Checks",
        },
        {
          path: "criminal",
          link: "https://in.springverify.com/screenings/criminal-record-check/",
          title: "Criminal Record Check",
        },
        {
          path: "employment",
          link:
            "https://in.springverify.com/screenings/employment-verification/",
          title: "Employment Verifications",
        },
        {
          path: "education",
          link:
            "https://in.springverify.com/screenings/education-verification/",
          title: "Education Verification",
        },
        {
          path: "global",
          link:
            "https://in.springverify.com/screenings/global-database-verification/",
          title: "Global Database Verification",
        },
        {
          path: "court",
          link: "https://in.springverify.com/screenings/court-record-check/",
          title: "Court Record Verification",
        },
        {
          path: "reference",
          link:
            "https://in.springverify.com/screenings/reference-verification/",
          title: "Reference Check",
        },
        {
          path: "address",
          link: "https://in.springverify.com/screenings/address-verification/",
          title: "Address Verification",
        },
      ],
      Illustration: [
        {
          path: "suiteChecks",
        },
      ],
    },
  ],

  StepsWrap: [
    {
      subHeader: "Easy onboarding",
      header: "Three simple steps to get started ",
      Illustration: [
        {
          link:
            "https://assets-sv-in.gumlet.io/LP/steps/steps-illustration.png",
        },
      ],
      Steps: [
        {
          path: "https://assets-sv-in.gumlet.io/LP/steps/din-upload.png",
          title: "Upload candidate information",
          desc:
            "Initiate the verification process as soon as your candidates enter their unique eight-digit DIN code",
        },
        {
          path: "https://assets-sv-in.gumlet.io/LP/steps/din-verify.png",
          title: "Verify the authenticity of the information provided",
          desc:
            "The Central Government’s Database is thoroughly scanned to confirm the candidate’s DIN code and find potential discrepancies.",
        },
        {
          path: "https://assets-sv-in.gumlet.io/LP/steps/din-hire.png",
          title: "Hire the right person",
          desc:
            "Keep tabs on the verification process and get notified if there’s any discrepancy such as bankruptcy, fraud, etc.",
        },
      ],
    },
  ],

  InfoCreditCheck: [
    {
      header: "Information you’ll know from \nCredit Checks",
      para: `Conducting credit checks is crucial for employers who are hiring for top financial and \nmanagerial roles. Here’s what you’ll come to know when conducting Credit Verifications:`,
      IconLink: [
        {
          path: "check-candidate",
          desc: "Candidate’s complete \nfinancial history.",
        },
        {
          path: "noticeable",
          desc: "Noticeable flags due to bad \nloans, bankruptcy or debts",
        },
        {
          path: "authenticity",
          desc: "Authenticity and \nAccountability of candidates",
        },
      ],
      Illustration: [
        {
          path: "info-creditcheck",
        },
      ],
    },
  ],

  InfoPassPort: [
    {
      header: "Information you will know from \nPassport Verifications",
      IconLink: [
        {
          path: "PVcandidate",
          desc:
            "Candidate’s nationality, \nidentity, address and other \npersonal information.",
        },
        {
          path: "authenticity",
          desc: "Authenticity and \naccountability of the \ncandidate.",
        },
        {
          path: "revelation",
          desc:
            "The revelation of unqualified \ncandidates - avoiding the \nrisk of bad hires",
        },
      ],
      Illustration: [
        {
          path: "passport-illustration",
        },
      ],
    },
  ],

  InfoCourtCovered: [
    {
      header: "List of Courts Covered",
      IconLink: [
        {
          path: "district",
          desc: "District court \nand tribunals",
        },
        {
          path: "courts",
          desc: "Courts and NCRDC \ncommission",
        },
        {
          path: "highCourt",
          desc: "High Courts and \nSupreme Court of India",
        },
      ],
      Illustration: [
        {
          path: "ListCourtsCovered",
        },
      ],
    },
  ],

  DINVerification: [
    {
      header: "Information you’ll know \nthrough DIN Verifications",
      para:
        "DIN Verification allows employers to gain a comprehensive understanding of their \ncandidate’s past and present employment history. \n\nIt can help you to:",
      IconLink: [
        {
          path: "candidate",
          desc:
            "Know if the candidate is \nassociated with illegal \nincidents",
        },
        {
          path: "access",
          desc:
            "Access information such as \nthe candidates share of the \nbusiness",
        },
        {
          path: "verify",
          desc:
            "Verify the authenticity of the \ncandidates claim of their \npast directorship",
        },
      ],
      Illustration: [
        {
          path: "info-creditcheck",
        },
      ],
    },
  ],

  FintechAllCheck: [
    {
      header: "SpringVerify’s suite of checks \nfor the Fintech Industry",
      para: `SpringVerify’s digital solutions make it easy for employers to screen candidates \nwithout hassle.
      Here are the recommended suite of checks for you as per the \nindustry standards`,
      IconLink: [
        {
          path: "id",
          link: "https://in.springverify.com/screenings/identity-verification/",
          title: "ID Checks",
        },
        {
          path: "criminal",
          link: "https://in.springverify.com/screenings/criminal-record-check/",
          title: "Criminal Record Check",
        },
        {
          path: "employment",
          link:
            "https://in.springverify.com/screenings/employment-verification/",
          title: "Employment Verifications",
        },
        {
          path: "education",
          link:
            "https://in.springverify.com/screenings/education-verification/",
          title: "Education Verification",
        },
        {
          path: "global",
          link:
            "https://in.springverify.com/screenings/global-database-verification/",
          title: "Global Database Verification",
        },
        {
          path: "court",
          link: "https://in.springverify.com/screenings/court-record-check/",
          title: "Court Record Verification",
        },
        {
          path: "reference",
          link:
            "https://in.springverify.com/screenings/reference-verification/",
          title: "Reference Check",
        },
        {
          path: "address",
          link: "https://in.springverify.com/screenings/address-verification/",
          title: "Address Verification",
        },
      ],
      Illustration: [
        {
          path: "FintechIndustry",
        },
      ],
    },
  ],

  HealthCareAllCheck: [
    {
      header: "Recommended \nHealthcare Checks",
      para: `It is important to onboard qualified healthcare personnel and deliver optimal care for your patients. Rightly so, here are a suite of checks we highly recommend:`,
      IconLink: [
        {
          path: "idcard",
          link: "https://in.springverify.com/screenings/identity-verification/",
          title: "ID Checks",
        },
        {
          path: "criminal",
          link: "https://in.springverify.com/screenings/criminal-record-check/",
          title: "Criminal Record Check",
        },
        {
          path: "employment",
          link:
            "https://in.springverify.com/screenings/employment-verification/",
          title: "Employment Verifications",
        },
        {
          path: "education",
          link:
            "https://in.springverify.com/screenings/education-verification/",
          title: "Education Verification",
        },
        {
          path: "global",
          link:
            "https://in.springverify.com/screenings/global-database-verification/",
          title: "Global Database Verification",
        },
        {
          path: "court",
          link: "https://in.springverify.com/screenings/court-record-check/",
          title: "Court Record Verification",
        },
        {
          path: "reference",
          link:
            "https://in.springverify.com/screenings/reference-verification/",
          title: "Reference Check",
        },
        {
          path: "address",
          link: "https://in.springverify.com/screenings/address-verification/",
          title: "Address Verification",
        },
      ],
      Illustration: [
        {
          path: "healthcarecheck",
        },
      ],
    },
  ],

  TechAllCheck: [
    {
      header: "Recommended Background \nChecks for Tech and IT Industry",
      para: `With proprietary and sensitive data at stake, it’s critical to hire people you can trust. So it is important to screen them before onboarding. Rightly so, here are the suite of checks we recommend:`,
      IconLink: [
        {
          path: "idcard",
          link: "https://in.springverify.com/screenings/identity-verification/",
          title: "ID Checks",
        },
        {
          path: "criminal",
          link: "https://in.springverify.com/screenings/criminal-record-check/",
          title: "Criminal Record Check",
        },
        {
          path: "employment",
          link:
            "https://in.springverify.com/screenings/employment-verification/",
          title: "Employment Verifications",
        },
        {
          path: "education",
          link:
            "https://in.springverify.com/screenings/education-verification/",
          title: "Education Verification",
        },
        {
          path: "global",
          link:
            "https://in.springverify.com/screenings/global-database-verification/",
          title: "Global Database Verification",
        },
        {
          path: "court",
          link: "https://in.springverify.com/screenings/court-record-check/",
          title: "Court Record Verification",
        },
        {
          path: "reference",
          link:
            "https://in.springverify.com/screenings/reference-verification/",
          title: "Reference Check",
        },
        {
          path: "address",
          link: "https://in.springverify.com/screenings/address-verification/",
          title: "Address Verification",
        },
      ],
      Illustration: [
        {
          path: "TechCheck",
        },
      ],
    },
  ],

  StaffingAgencies: [
    {
      header: "Recommended Background \nChecks for Tech and IT Industry",
      para: `With proprietary and sensitive data at stake, it’s critical to hire people you can trust. So it is important to screen them before onboarding. Rightly so, here are the suite of checks we recommend:`,
      IconLink: [
        {
          path: "id",
          link: "https://in.springverify.com/screenings/identity-verification/",
          title: "ID Checks",
        },
        {
          path: "criminal",
          link: "https://in.springverify.com/screenings/criminal-record-check/",
          title: "Criminal Record Check",
        },
        {
          path: "employment",
          link:
            "https://in.springverify.com/screenings/employment-verification/",
          title: "Employment Verifications",
        },
        {
          path: "education",
          link:
            "https://in.springverify.com/screenings/education-verification/",
          title: "Education Verification",
        },
        {
          path: "global",
          link:
            "https://in.springverify.com/screenings/global-database-verification/",
          title: "Global Database Verification",
        },
        {
          path: "court",
          link: "https://in.springverify.com/screenings/court-record-check/",
          title: "Court Record Verification",
        },
        {
          path: "reference",
          link:
            "https://in.springverify.com/screenings/reference-verification/",
          title: "Reference Check",
        },
        {
          path: "address",
          link: "https://in.springverify.com/screenings/address-verification/",
          title: "Address Verification",
        },
      ],
      Illustration: [
        {
          path: "staffingCheck",
        },
      ],
    },
  ],

  ComplianceAllCheck: [
    {
      header: "Recommended suite of checks \nfor Compliance and Risk Teams",
      para: `Reducing risk and conducting a fair, compliant hiring process is what builds trust and brand reputation.
      Here are the recommended suite of checks for you as per the industry standards`,
      IconLink: [
        {
          path: "id",
          link: "https://in.springverify.com/screenings/identity-verification/",
          title: "ID Checks",
        },
        {
          path: "address",
          link: "https://in.springverify.com/screenings/address-verification/",
          title: "Address Verification",
        },
        {
          path: "employment",
          link:
            "https://in.springverify.com/screenings/employment-verification/",
          title: "Employment Verifications",
        },
        {
          path: "reference",
          link:
            "https://in.springverify.com/screenings/reference-verification/",
          title: "Reference Check",
        },
        {
          path: "criminal",
          link: "https://in.springverify.com/screenings/criminal-record-check/",
          title: "Criminal Record Check",
        },
        {
          path: "education",
          link:
            "https://in.springverify.com/screenings/education-verification/",
          title: "Education Verification",
        },
        {
          path: "court",
          link: "https://in.springverify.com/screenings/court-record-check/",
          title: "Court Record Verification",
        },
        {
          path: "global",
          link:
            "https://in.springverify.com/screenings/global-database-verification/",
          title: "Global Database Verification",
        },
        {
          path: "credit",
          link: "https://in.springverify.com/screenings/credit-check/",
          title: "Credit Check",
        },
        {
          path: "social",
          link:
            "https://in.springverify.com/screenings/social-media-verification/",
          title: "Social Media Verification",
        },
        {
          path: "drug",
          link:
            "https://in.springverify.com/screenings/drug-test-verification/",
          title: "Drug Test Screening",
        },
        {
          path: "passport",
          link: "https://in.springverify.com/screenings/passport-verification/",
          title: "Passport Verification",
        },
        {
          path: "din",
          link: "https://in.springverify.com/screenings/din-verification/",
          title: "DIN Verification",
        },
      ],
      Illustration: [
        {
          path: "allCheckCompliance",
        },
      ],
    },
  ],

  OperationAllCheck: [
    {
      header: "Recommended checks \nfor Operation teams",
      IconLink: [
        {
          path: "id",
          link: "https://in.springverify.com/screenings/identity-verification/",
          title: "ID Checks",
        },
        {
          path: "address",
          link: "https://in.springverify.com/screenings/address-verification/",
          title: "Address Verification",
        },
        {
          path: "employment",
          link:
            "https://in.springverify.com/screenings/employment-verification/",
          title: "Employment Verifications",
        },
        {
          path: "reference",
          link:
            "https://in.springverify.com/screenings/reference-verification/",
          title: "Reference Check",
        },
        {
          path: "criminal",
          link: "https://in.springverify.com/screenings/criminal-record-check/",
          title: "Criminal Record Check",
        },
        {
          path: "education",
          link:
            "https://in.springverify.com/screenings/education-verification/",
          title: "Education Verification",
        },
        {
          path: "court",
          link: "https://in.springverify.com/screenings/court-record-check/",
          title: "Court Record Verification",
        },
        {
          path: "global",
          link:
            "https://in.springverify.com/screenings/global-database-verification/",
          title: "Global Database Verification",
        },
        {
          path: "credit",
          link: "https://in.springverify.com/screenings/credit-check/",
          title: "Credit Check",
        },
        {
          path: "social",
          link:
            "https://in.springverify.com/screenings/social-media-verification/",
          title: "Social Media Verification",
        },
        {
          path: "drug",
          link:
            "https://in.springverify.com/screenings/drug-test-verification/",
          title: "Drug Test Screening",
        },
        {
          path: "passport",
          link: "https://in.springverify.com/screenings/passport-verification/",
          title: "Passport Verification",
        },
        {
          path: "din",
          link: "https://in.springverify.com/screenings/din-verification/",
          title: "DIN Verification",
        },
      ],
      Illustration: [
        {
          path: "operation",
        },
      ],
    },
  ],

  SolvingVerify: [
    {
      Header: "Here’s how SpringVerify can help you",
      HRSpringVerifyCard: [
        {
          title: "AI and Blockchain to \nHelp You Hire at Scale",
          desc: `Whether it's screening the right talent or scaling your verification needs - our AI and Blockchain-powered 
          technology help you expand, optimize and hire at scale effortlessly.`,
        },
        {
          title: "Easy integration with \nYour Existing HR Systems",
          desc:
            "SpringVerify integrates with your existing ATS and HRIS systems easily. Meaning you don't have to spend dedicated time, resources, and effort to get started with us.",
        },
        {
          title: "Increase Productivity with Workflow \nAutomation",
          desc:
            "Eliminate error-prone manual work. Automate repetitive tasks to get faster, reliable, and more accurate results.",
        },
        {
          title: "Go Paperless. \nSave Time.",
          desc:
            "Our paperless process makes your verifications efficient and smooth. Plus, it empowers candidates to provide and receive information directly without too much hassle.",
        },
      ],
    },
  ],

  HRSpringVerify: [
    {
      Header: "Here’s how SpringVerify can help you",
      HRSpringVerifyCard: [
        {
          title: "Ensure quality hiring",
          desc: `Not every time what a candidate declares during interviews turns out to be true. Running background checks can help you hire the right candidates with the right credentials.`,
        },
        {
          title: "Avoid costly mis-hires",
          desc:
            "As the cost of mis-hires is very high, ensure only verified candidates get into your onboarding process to save time, resources, and money.",
        },
        {
          title: "Build and customize your verification \nfunnel effortlessly",
          desc:
            "Build a flexible yet robust verification process based on your company requirements. Run checks, hire candidates, and hit your hiring goals depending on your needs.",
        },
        {
          title: "Compliant with Top Rules and \nRegulations",
          desc:
            "SpringVerify is certified with ISO 27001:9000, PCI, and more. You can rest assured that the candidates you verify are on par with the industrial standards.",
        },
      ],
    },
  ],

  FintechCompanies: [
    {
      path: "realTime",
      title: "Real-time and Instant \nVerification",
      desc: `With SpringVerify’s digital solutions, you’ll have the flexibility to run background checks in real-time
        and make instant hiring decisions easily. From providing data on active crimes to running credit checks, SpringVerify
        does it seamlessly.`,
    },
    {
      path: "enhanced",
      title: "Enhanced Workflow \nAutomation",
      desc:
        "Save time by automating the verification  process with SpringVerify’s AI-powered blockchain technology. Get real-time updates and hire at scale effortlessly.",
    },
    {
      path: "seamless",
      title: "Seamless Candidate \nExperience",
      desc: `SpringVerify, with its unparalleled UX, makes it simple for candidates to submit documents and track their
        candidature status in real-time. In addition, our built-in mobile-optimized dashboard offers candidates a seamless experience throughout the screening process.`,
    },
  ],

  TATeams: [
    {
      path: "optimize",
      title: "Optimize your Verification \nProcess Effortlessly",
      desc: `Convert qualified candidates faster by screening them based on the position you are filling.
        Provide equal ground for all who could be a great fit for the role you’re filling for.`,
    },
    {
      path: "deliver",
      title: "Deliver Friction-free Candidate \nExperience",
      desc: `Offer your Candidates a smooth, quick, and memorable experience. Our all-new candidate portal is built to assist them through
        every step of the process - from tracking status to reaching out to our dedicated support team in case of queries.`,
    },
    {
      path: "compliant",
      title: "Compliant with Top Rules and \nRegulations",
      desc:
        "SpringVerify is compliant with ISO 27001:9000, PCI, and more. You can rest assured that the candidates you verify are in par with the industrial standards. ",
    },
  ],

  ECommerceTATeams: [
    {
      header: "Customized Solutions built for \nE-commerce Companies",
      IconLink: [
        {
          path: "optimize",
          title: "Optimize your Verification \nProcess Effortlessly",
          desc: `Convert qualified candidates faster by screening them based on the position you are filling.
          Provide equal ground for all who could be a great fit for the role you’re filling for.`,
        },
        {
          path: "deliver",
          title: "Deliver Friction-free Candidate \nExperience",
          desc: `Offer your Candidates a smooth, quick, and memorable experience. Our all-new candidate portal is built to assist them through
          every step of the process - from tracking status to reaching out to our dedicated support team in case of queries.`,
        },
        {
          path: "compliant",
          title: "Compliant with Top Rules and \nRegulations",
          desc:
            "SpringVerify is compliant with ISO 27001:9000, PCI, and more. You can rest assured that the candidates you verify are in par with the industrial standards. ",
        },
      ],
    },
  ],

  CriminalVerify: [
    {
      path: "https://assets-sv-in.gumlet.io/LP/CriminalVerify/verbal.svg",
      header: "Verbal Police Verification",
      desc:
        "Based on the address information of the candidate, the nearest police station is contacted. Our Law Firm partner will run a thorough check and produce a final report with the comments from the concerned authorities.",
    },
    {
      path: "https://assets-sv-in.gumlet.io/LP/CriminalVerify/police.svg",
      header: "Police Clearance Certificate",
      desc:
        "Authorities will run on-ground checks by meeting with the candidates directly. Once verified, a Police Clearance Certificate with detailed information on any prevailing criminal charges is established.",
    },
    {
      path: "https://assets-sv-in.gumlet.io/LP/CriminalVerify/court.svg",
      header: "Court Record Verification",
      desc:
        "Get detailed information of any lawsuits, criminal cases, or ongoing trials against the candidate.",
    },
  ],

  APIUnique: [
    {
      path: "https://assets-sv-in.gumlet.io/LP/APIUnque/1.svg",
      header: "Easy integration",
    },
    {
      path: "https://assets-sv-in.gumlet.io/LP/APIUnque/2.svg",
      header: "Set-up in less \nthan 4 hours",
    },
    {
      path: "https://assets-sv-in.gumlet.io/LP/APIUnque/3.svg",
      header: "Predictable resource \noriented URLs",
    },
    {
      path: "https://assets-sv-in.gumlet.io/LP/APIUnque/4.svg",
      header: "ISO and PCI \ncertified",
    },
    {
      path: "https://assets-sv-in.gumlet.io/LP/APIUnque/5.svg",
      header: "Industry-leading \nturnaround time",
    },
    {
      path: "https://assets-sv-in.gumlet.io/LP/APIUnque/6.svg",
      header: "Powered by AI \nand Blockchain",
    },
    {
      path: "https://assets-sv-in.gumlet.io/LP/APIUnque/7.svg",
      header: "Guaranteed 99.9% \nuptime SLA",
    },
    {
      path: "https://assets-sv-in.gumlet.io/LP/APIUnque/8.svg",
      header: "Dedicated \nAccount Manager",
    },
  ],

  EnterpriseBusiness: [
    {
      path: "https://assets-sv-in.gumlet.io/LP/enterprise/easy.svg",
      header: "Easy integration and reliability",
      desc:
        "SpringVerify is built to adapt and unify with your existing HRMS and ATS systems. Plus all the checks are in accordance with top laws like ISO, PCI, and more.",
    },
    {
      path: "https://assets-sv-in.gumlet.io/LP/enterprise/uncompromising.svg",
      header: "Uncompromising security and compliance",
      desc:
        "SpringVerify is compliant with all the national and international laws. All your data is secured with end-to-end encryption and checked periodically without any compromise. After all - security and privacy are at the heart of everything we do.",
    },
    {
      path: "https://assets-sv-in.gumlet.io/LP/enterprise/bespoke.svg",
      header: "Bespoke customizations based on \nrequirements",
      desc:
        "Through granular customizations, powerful APIs, and low to no-code platforms - SpringVerify lets you automate everyday verification processes, and practice world-class solutions at scale",
    },
    {
      path: "https://assets-sv-in.gumlet.io/LP/enterprise/super-skilled.svg",
      header: "Super-skilled dedicated \nsupport team",
      desc:
        "With strict SLA practices in place, you'll get access to highly skilled support engineers on standby - 24 hours a day, 365 days a year. Their in-depth product knowledge will help you solve your unique challenges and deploy custom solutions as soon as possible.",
    },
  ],

  EndToEnd: [
    {
      header: "Requirement analysis",
      desc:
        "We understand your company landscape and business processes to identify challenges, gaps, and solve background verification challenges.",
    },
    {
      header: "Build customized solutions",
      desc:
        "Collate insights, and build tailored verification solutions based on your business requirements.",
    },
    {
      header: "Assisted onboarding",
      desc:
        "We help you up-skill your organization by scheduling in-person or team-wide training programs with one of our product experts.",
    },
    {
      header: "Effortless data migration",
      desc:
        "Our dedicated team of experts helps you shift your data from existing solution providers ensuring a smooth transition with no errors or data loss.",
    },
    {
      header: "Prototyping & \nimplementation",
      desc:
        "We align SpringVerify with your existing processes in a precise and efficient manner with customized implementation and support.",
    },
    {
      header: "Post-purchase training and \nsupport",
      desc:
        "Our Dedicated Account Manager works in tandem with your company to deliver the most personalized experience post-implementation.",
    },
  ],

  DevByDev: [
    {
      header: "Plugs into your \nexisting tools seamlessly",
      desc:
        "Our API integrates with the HRMS and ATS tools you already use. You don't have to dedicate a special time or effort to get started with us.",
      link: "https://docs-bgv.springverify.com/#introduction",
      anchor: "Access our API Documentation",
      arrow: "https://assets-sv-in.gumlet.io/LP/dev-by-dev/right-arrow.svg",
    },
    {
      header: "Our API commitment and \npublic documentation",
      desc:
        "Our flexible and easy-to-use API documentation helps you get started instantly. Build a tailored solution that fits your organization’s needs.",
      link: "https://docs-bgv.springverify.com/#introduction",
      anchor: "Access our API Documentation",
      arrow: "https://assets-sv-in.gumlet.io/LP/dev-by-dev/right-arrow.svg",
    },
    {
      header: "Embrace transparency and \ncontrol with Blockchain & AI",
      desc:
        "Get the combined benefits of an AI and Blockchain to boost accuracy, speed, and reliability with every background check you make. ",
    },
    {
      header: "Increase productivity with workflow automation",
      desc:
        "Wave goodbye to repetitive manual tasks and cumbersome paperwork. Save time and focus on what matters by automating your verifications easily.",
    },
    {
      header: "Comes equipped with \nWebhook endpoints",
      desc:
        "You don’t have to call our API to know the status of the checks. SpringVerify automatically notifies and keeps you up-to-date at every step of the process.",
    },
    {
      header: "Build a fool-proof system with \na pre-production environment",
      desc:
        "Conduct tests in our pre-production environment with mock data. This can save time and effort compared to experimenting with real-time data.",
    },
  ],

  ECompTATeams: [
    {
      path: "streamline",
      title: "Streamline your Bulk and \nSeasonal Hiring Spikes",
      desc: `SpringVerify is designed to scale hiring for businesses of all sizes.
      Meet growing demands effortlessly with real-time instant background checks tailored to your unique needs.`,
    },
    {
      path: "experience",
      title: "Deliver a Delightful Candidate \nExperience",
      desc: `SpringVerify, with its mobile-optimized dashboard and unparalleled UX,
        makes it simple for candidates to submit documents and track the status of their background checks in real-time.
        A simple yet effective way for employers to streamline their hiring process.`,
    },
    {
      path: "workplace",
      title: "Build a Safe and Secure \nWorkplace",
      desc: `When it comes to hiring for E-commerce companies, building trust and safety into your hiring process is important.
      In addition, a candidate's authenticity and loyalty play a huge role in a remote working environment and upholds your brand reputation.`,
    },
  ],

  CriminalVerification: [
    {
      path: "https://assets-sv-in.gumlet.io/LP/CriminalVerification/verbal.svg",
      title: "Verbal Police Verification",
      desc: `Based on the address information of the candidate, the nearest police station is contacted. 
      Our Law Firm partner will run a thorough check and produce a final report with the comments from the concerned authorities.`,
    },
    {
      path: "https://assets-sv-in.gumlet.io/LP/CriminalVerification/police.svg",
      title: "Police Clearance Certificate",
      desc: `Authorities will run on-ground checks by meeting with the candidates directly. 
      Once verified, a Police Clearance Certificate with detailed information on any prevailing criminal charges is established.`,
    },
    {
      path: "https://assets-sv-in.gumlet.io/LP/CriminalVerification/court.svg",
      title: "Court Record Verification",
      desc: `Get detailed information of any lawsuits, criminal cases, or ongoing trials against the candidate.`,
    },
  ],
};

export default Data;
